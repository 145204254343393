import { Component, OnInit } from '@angular/core';
import { CustomService } from '../services/custom.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private customService: CustomService, private router: Router) { }
  loginUser = {username:'', password: ''};

  ngOnInit(): void {
  }
  login = (): void => {
    if (this.loginUser.username.length && this.loginUser.password.length) {
      this.customService.createLogin(this.loginUser)
        .subscribe(response => {
          if (response['token']) {
            localStorage.setItem('token', response['token']);
            localStorage.setItem('expire', response['expire']);
            this.router.navigateByUrl('/selectpage');
          }
      });
    }

    //TODO: poista navigointi tuotantoonsiirrossa
    //this.router.navigateByUrl('/selectpage');
  }

}
