<a href="/selectpage" style="text-decoration:none; color: white;">Takaisin päävalikkoon</a>
&nbsp;<br />
&nbsp;<br />

<div class="grid-wrapper">
  <h2>{{ place }} Minigolf</h2>
  <h2>Uudet TOP-tulokset</h2>
  <div *ngFor="let nonVerifiedPlayer of nonVerifiedPlayers; let iMain = index">
    <div style="color: green" class="grid-container" *ngFor="let oneNonVerifiedPlayer of nonVerifiedPlayer.gamers; let i = index">
      <div class="grid-item">
        {{oneNonVerifiedPlayer.score}}
      </div>
      <div id="second" class="grid-item">
        {{oneNonVerifiedPlayer.name}}
      </div>
      <div class="grid-item">
        <button class="removeButton" (click)="discardPlayer(nonVerifiedPlayer, oneNonVerifiedPlayer.name, iMain, 'nonVerifiedPlayers')">Poista</button>
      </div>
    </div>
  <div style="text-align: center;">
    <button class="addButton" (click)="verifyGame(nonVerifiedPlayer)">Lisää kaikki</button>
    <button class="removeButton" (click)="discardGame(nonVerifiedPlayer)">Poista kaikki</button><br><br>
  </div>
</div>

<div>
  <h2>TOP 100 -tulokset</h2>

  <div *ngFor="let verifiedPlayer of verifiedPlayers; let iMain = index">
    <span>{{verifiedPlayer.name}}</span>  
    <span>{{verifiedPlayer.score}}</span>
    <button (click)="discardPlayer(verifiedPlayer, verifiedPlayer.name, iMain, 'verifiedPlayers')">Poista</button><br><br>
  </div>
</div>


<!-- https://minigolf.api.fin.grassdigital.fi/getNonVerified
<div>
  [hyväksmättömät]
<br>
<br> materiaalit voi hakea
<br><b>  curl --request GET \
  --url http://localhost:10000/getNonVerified \
  --header 'Content-Type: application/json'</b>


<br />    - obj  <button>hyväksy </button>
<br />    - obj  <button>hyväksy </button>
<br />    - obj  <button>hyväksy </button>
<br />    - obj  <button>hyväksy </button>
<br />    - obj  <button>hyväksy </button>
<br />
<br>  [hyväksytyt]
<br>
<br> materiaalit voi hakea
 <br><b> curl --request GET \
  --url http://localhost:10000/getNonVerified \
  --header 'Content-Type: application/json'</b>
<br>
<br>  [hylätyt]

</div> -->
