import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SelectpageComponent } from './selectpage/selectpage.component';
import { ManagementComponent } from './management/management.component';

const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'LoginComponent', component: LoginComponent},
  {path: 'selectpage', component: SelectpageComponent},
  {path: 'management', component: ManagementComponent},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
