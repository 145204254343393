import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css']
})


export class ManagementComponent implements OnInit {
  verifiedPage = false;
  nonVerifiedPage = true;
  dbtable = 'gamers';
  place = 'Bucket';
  validToken = localStorage.getItem('expire') ? localStorage.getItem('expire') : 0 ;
  title = 'admin.minigolfscore.fi';
  verifiedPlayers: any = [];
  nonVerifiedPlayers: any = [];

  getVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getVerified';
  getNonVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getNonVerified?game=gamers';
  verifyScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/verifyScore';
  discardScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/discardScore';
  deleteScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/deleteid';
  getPlayersUrl = 'https://minigolf.api.fin.grassdigital.fi/gettopplayers?game=gamers&management=1';

  constructor(private httpClient: HttpClient, private router: Router) { }

  ngOnInit(){

    if(!this.isValidToken(this.validToken)){
      this.router.navigateByUrl('/login');
    }

    if (window.location.href && window.location.href.includes("levi")) {
      this.dbtable = 'levi';
      this.place = 'Levi';
      this.getVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getVerified';
      this.getNonVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getNonVerified?game=levi';
      this.verifyScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/verifyScore';
      this.discardScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/discardScore';
      this.deleteScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/deleteid';
      this.getPlayersUrl = 'https://minigolf.api.fin.grassdigital.fi/gettopplayers?game=levi&management=1';
    }

    if (window.location.href && window.location.href.includes("ylabucket")) {
      this.dbtable = 'ylabucket';
      this.place = 'Bucket yläkerta';
      this.getVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getVerified';
      this.getNonVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getNonVerified?game=ylabucket';
      this.verifyScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/verifyScore';
      this.discardScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/discardScore';
      this.deleteScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/deleteid';
      this.getPlayersUrl = 'https://minigolf.api.fin.grassdigital.fi/gettopplayers?game=ylabucket&management=1';
    }

  if (window.location.href && window.location.href.includes("alabucket")) {
      this.dbtable = 'alabucket';
      this.place = 'Bucket alakerta';
      this.getVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getVerified';
      this.getNonVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getNonVerified?game=alabucket';
      this.verifyScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/verifyScore';
      this.discardScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/discardScore';
      this.deleteScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/deleteid';
      this.getPlayersUrl = 'https://minigolf.api.fin.grassdigital.fi/gettopplayers?game=alabucket&management=1';
    }
  if (window.location.href && window.location.href.includes("hw-sjk")) {
      this.dbtable = 'hw-sjk';
      this.place = 'hw-sjk';
      this.getVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getVerified';
      this.getNonVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getNonVerified?game=hw-sjk';
      this.verifyScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/verifyScore';
      this.discardScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/discardScore';
      this.deleteScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/deleteid';
      this.getPlayersUrl = 'https://minigolf.api.fin.grassdigital.fi/gettopplayers?game=hw-sjk&management=1';
    }

    this.getNonVerifiedPlayers().subscribe(response => {
      this.nonVerifiedPlayers = response;
    });

    this.getTopPlayers().subscribe(response => {
      this.verifiedPlayers = response;
    });

  }
  getNonVerifiedPlayers() {
    const headers = {"Authorization":"Bearer: " + localStorage.getItem('token')};
    return this.httpClient.get<any>(this.getNonVerifiedUrl, { headers });
  }

  getTopPlayers(): any {
    const headers = {"Authorization":"Bearer: " + localStorage.getItem('token')};
    return this.httpClient.get<any>(this.getPlayersUrl, { headers });
  }

  verifyGame(player: any) {
    const headers = {"Content-Type":"application/json", "Authorization":"Bearer: " + localStorage.getItem('token')};
    const body = {key: player._id, gamers: player.gamers, game: this.dbtable};
    this.httpClient.post(this.verifyScoreUrl, body, { headers, responseType: 'json' }).subscribe(response => {
      this.nonVerifiedPlayers = this.nonVerifiedPlayers.filter(value => value._id !== player._id);
    });
  }

  discardGame(player: any) {
    if(confirm("Haluatko varmasti poistaa tulokset?")) {
      const headers = {"Content-Type":"application/json", "Authorization":"Bearer: " + localStorage.getItem('token')};
      const body = {key: player._id, gamers: player.gamers, game: this.dbtable};
      this.httpClient.post(this.discardScoreUrl, body, { headers, responseType: 'json' }).subscribe(response => {
        this.nonVerifiedPlayers = this.nonVerifiedPlayers.filter(value => value._id !== player._id);
      });
    }
  }

  discardPlayer(player: any, nameOfRemoved: any, iMain: any, selectedArray: any) {
    if(confirm("Haluatko varmasti poistaa pelaajan tuloksen?")) {
      if (selectedArray === 'nonVerifiedPlayers') {
        this[selectedArray][iMain].gamers = this[selectedArray][iMain].gamers.filter(value => value.name !== nameOfRemoved);
      } else {
        const headers = {"Content-Type":"application/json", "Authorization":"Bearer: " + localStorage.getItem('token')};
        const body = {id: player.gamerid, player: player, game: this.dbtable};
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', "Authorization":"Bearer: " + localStorage.getItem('token')}), body: body
        };
        this.httpClient.delete(this.deleteScoreUrl, httpOptions).subscribe(response => {
          this[selectedArray] = this[selectedArray].filter(value => value.name !== nameOfRemoved);
        });
      }
    }
  }

  isValidToken(validToken) {
    return (Date.now() < validToken*1000 ) ? true : false;
  }

}
