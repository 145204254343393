import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

type GameData = {
  _id: string
  email: string
  gamers: Array<{
    name: string
    score: number
  }>
  confirmed: boolean
  verified: boolean
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  verifiedPage = false;
  nonVerifiedPage = true;

  title = 'admin.minigolfscore.fi';
  verifiedPlayers: any = [];
  nonVerifiedPlayers: any = [];
  
  getVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getVerified';
  getNonVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getNonVerified';
  verifyScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/verifyScore';
  discardScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/discardScore';

  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
    const hostname = window.location.pathname;
    if (hostname.includes('levi')) {
      this.getVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getVerified';
      this.getNonVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getNonVerified';
      this.verifyScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/verifyScore';
      this.discardScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/discardScore';
    }

    if (hostname.includes('alabucket')) {
      this.getVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getVerified';
      this.getNonVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getNonVerified';
      this.verifyScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/verifyScore';
      this.discardScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/discardScore';
    }

    if (hostname.includes('ylabucket')) {
      this.getVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getVerified';
      this.getNonVerifiedUrl = 'https://minigolf.api.fin.grassdigital.fi/getNonVerified';
      this.verifyScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/verifyScore';
      this.discardScoreUrl = 'https://minigolf.api.fin.grassdigital.fi/discardScore';
    }

    this.getNonVerifiedPlayers().subscribe(response => {
      this.nonVerifiedPlayers = response;
    });
  }

  getVerifiedPlayers() {
    return this.httpClient.get<any>(this.getVerifiedUrl);
  }
  getNonVerifiedPlayers() {
    return this.httpClient.get<any>(this.getNonVerifiedUrl);
  }

  verifyGame(player: any) {
    const headers = {"Content-Type":"application/json"};
    const body = {key: player._id};
    this.httpClient.post(this.verifyScoreUrl, body, { headers, responseType: 'json' }).subscribe(response => {
      this.nonVerifiedPlayers = this.nonVerifiedPlayers.filter(value => value._id !== player._id);
    });
  }

  discardGame(player: any) {
    if(confirm("Haluatko varmasti poistaa tulokset")) {
      const headers = {"Content-Type":"application/json"};
      const body = {key: player._id};
      this.httpClient.post(this.discardScoreUrl, body, { headers, responseType: 'json' }).subscribe(response => {
        this.verifiedPlayers = this.verifiedPlayers.filter(value => value._id !== player._id);
      });
    }
  }

  showNonVerified() {
    this.getNonVerifiedPlayers().subscribe(response => {
      this.nonVerifiedPlayers = response;
    });
  }
  showVerified() {
    this.getVerifiedPlayers().subscribe(response => {
       this.verifiedPlayers = response;
    });
  }

}
