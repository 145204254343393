import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class CustomService {
  private loginUrl = 'https://minigolf.api.fin.grassdigital.fi/login';
  private cardsUrl = 'http://localhost:3000/cards';
  private saveUrl = 'http://localhost:3000/saveCard';
  private addNumbersUrl = 'http://localhost:3000/saveCard';


  constructor(private httpClient: HttpClient) { }

  createLogin(data: object){
    const headers = {"Content-Type":"application/json"};
    return this.httpClient.post(this.loginUrl, data, { headers, responseType: 'json' });
  }

  saveCard(data: object){
    const headers = {"Content-Type":"application/json"};
    return this.httpClient.post(this.saveUrl, data, { headers, responseType: 'json' });
  }

  addNumbers(data: object){
    const headers = {"Content-Type":"application/json"};
    return this.httpClient.post(this.addNumbersUrl, data, { headers, responseType: 'json' });
  }

  getFreeCards(){
    return this.httpClient.get<any>(this.cardsUrl);
  }
}
