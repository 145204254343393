<div class="loginContainer">
  <div class="loginChild">
      <br>
      <div class="loginService">
            <br>
        <div style="width: 100%; height:5px;">&nbsp;</div>
      <a href="/management?place=levi"><button class="button">Levi minigolf</button></a>
        <div style="width: 100%; height:5px;">&nbsp;</div>
      <a href="/management?place=bucket"><button class="button">Bucket minigolf</button></a>
        <div style="width: 100%; height:5px;">&nbsp;</div>
      <a href="/management?place=ylabucket"><button class="button">Bucket minigolf yl&auml;kerta</button></a>
        <div style="width: 100%; height:5px;">&nbsp;</div>
      <a href="/management?place=alabucket"><button class="button">Bucket minigolf alakerta</button></a>
        <div style="width: 100%; height:5px;">&nbsp;</div>
      <a href="/management?place=hw-sjk"><button class="button">Hollywood SJK</button></a>

   </div>
  </div>
</div>
