<div class="loginContainer">
  <div class="loginChild">
      <br>
      <div class="loginService">Kirjaudu palveluun</div>
        <div style="width: 100%; min-height:10px;">&nbsp;</div>
      <form name="loginForm" label="formData">
        <div style="width: 100%; min-height:10px;"></div>
        <input name="loginname" class="inputfield" placeholder="Käyttäjätunnus" type="text" [(ngModel)]="loginUser.username">
        <div style="width: 100%; height:5px;">&nbsp;</div>
        <input name="loginname" class="inputfield" placeholder="Salasana" type="text" [(ngModel)]="loginUser.password">
      </form>
        <div style="width: 100%; height:5px;">&nbsp;</div>
      <button class="button" (click)="login()">Kirjaudu sisään</button>

  </div>
</div>
